import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import blogPosts from './blog.json';
import { Navigation } from './lp/navigation';
import { Contact } from './lp/contact';
import landingPageData from './data/data.json';

const BlogPreview = ({ post }) => {
    return (
        <div>
            <h3>{post.title}</h3>
            <Link to={`/blog/${post.id}`}><img src={post.image} alt={post.title} height="200px" width="100%" style={{ objectFit: "cover" }}/></Link>
            <p>{post.preview.replace('ß', 'ss')}</p>
            <Link to={`/blog/${post.id}`}>Mehr</Link>
        </div>
    );
};


const Blog = () => {

    return (
        <Fragment>
        <Navigation />
        <div className='container'>
                <div className="section-title ">
        
            <div className="col-md-10 col-md-offset-1 section-title text-center">
                <h2>Blog</h2></div>
                <div className="row">

                
                        {blogPosts.map((post) => (
                            <div className="col-xs-6 col-md-3">
                            <BlogPreview key={post.id} post={post} />
                            </div>
                        ))}
                        </div>
            </div>
        </div>
        <Contact data={landingPageData.Contact} />
        </Fragment>
    );
};

export default Blog;
