import React, { Fragment } from 'react';
import { globalVars } from './Constants';
import { Navigation } from './lp/navigation';

const Privacy = () => {
    return (
        <Fragment>
            <Navigation />
            <div className='container'>
                <div className="section-title text-center">

            <h2>Datenschutzrichtlinie</h2></div>

            <div className='container'>
            <h3>Einführung</h3>

            <p>Wir bei EsGehtMirGut.ch verpflichten uns zum Schutz Ihrer Privatsphäre. Diese Datenschutzrichtlinie gilt für alle unsere Dienstleistungen, die über unsere App zugänglich sind. Durch die Nutzung unserer App stimmen Sie der Sammlung, Übertragung, Verarbeitung, Speicherung, Offenlegung und anderen Verwendungsarten Ihrer Informationen gemäss dieser Datenschutzrichtlinie zu.</p>

            <h3>Informationssammlung und Nutzung</h3>

            <p>Um unsere Dienste bereitzustellen und zu verbessern, erfassen wir Informationen, die Sie uns zur Verfügung stellen, einschliesslich, aber nicht beschränkt auf:</p>
            <p>Persönliche Identifikationsinformationen: Name, WhatsApp-Nummer und andere Kontaktdaten.</p>
            <p>Nutzungsdaten: Informationen darüber, wie Sie den Dienst nutzen, einschliesslich Zugriffszeiten und gesendete Nachrichten.</p>
            <p>Teilen und Offenlegen von Informationen</p>

            <p>Wir geben Ihre persönlichen Daten nicht an Dritte weiter, ausser in folgenden Fällen:</p>

            <p>Zur Bereitstellung des Dienstes: Wir teilen Ihre WhatsApp-Nummer mit WhatsApp, einem Dienst von Facebook, Inc., um die Nachrichtenübermittlung zu ermöglichen.</p><p>
            Rechtliche Anforderungen: Wenn wir gesetzlich dazu verpflichtet sind oder wenn es notwendig ist, um unsere Rechte zu schützen.
            Datenspeicherung und Sicherheit</p>

            <p>Wir ergreifen angemessene Massnahmen, um Ihre persönlichen Daten vor unbefugtem Zugriff oder unbefugter Offenlegung zu schützen. Ihre Daten werden so lange aufbewahrt, wie es für die Bereitstellung unserer Dienste erforderlich ist, oder um gesetzlichen Verpflichtungen nachzukommen.</p>
            <h3>Ihre Datenschutzrechte</h3>

            <p>Als Nutzer unserer App haben Sie das Recht:

            <ul><li>Auskunft über die von uns gespeicherten persönlichen Daten zu verlangen.</li>
            <li>Berichtigung unrichtiger Daten zu verlangen.</li>
            <li>Löschung Ihrer persönlichen Daten zu verlangen.</li>
            <li>Bitte kontaktieren Sie uns, wenn Sie eines Ihrer Rechte ausüben möchten.</li></ul></p>

            <h3>Änderungen an dieser Datenschutzrichtlinie</h3>

            <p>Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Änderungen werden auf unserer Website und/oder App bekannt gegeben. Ihre fortgesetzte Nutzung unseres Dienstes nach solchen Änderungen stellt Ihre Zustimmung zu den aktualisierten Richtlinien dar.</p>
            
            <h3>Kontakt</h3>

            <p>Bei Fragen oder Bedenken bezüglich dieser Datenschutzrichtlinie kontaktieren Sie uns bitte unter: office@hankeconsulting.ch</p>

            <p>EsGehtMirGut.ch, Seestrasse 314, Zürich, Schweiz</p>
            </div></div>
        </Fragment>
    );
};

export default Privacy;
