import React, { useState, useEffect, Fragment } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { globalVars } from '../Constants';
import { Navigation } from '../lp/navigation';

const isServerless = process.env.REACT_APP_SERVERLESS === 'true';
const stripePromise = isServerless
  ? loadStripe('pk_live_51IHEi8Db1bsMIykoILMWo270g6xUIUNhzcpCO4ExSBgy3gZpB785hYeVWBesrmf0VJibVuVIrWtETHBDO292OEMy00kuCBUxMd', { locale: 'de' })
  : loadStripe('pk_test_51IHEi8Db1bsMIykosP3NM48VcwtM2N3B9lfGXzw3Y1uCbf691oX1NzThg1RF6XM2fhlYU3uzmU3mJ37WbSAyDdI400HLIDWQGU', { locale: 'de' });

  const plan = isServerless ? 'price_1OsBdNDb1bsMIykoR1rEeTCJ' : 'price_1OpHiDDb1bsMIyko5j38zQfT';

  const PaymentPage = () => {
    const [clientSecret, setClientSecret] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const createSubscription = async () => {
            try {
                const apiUrl = isServerless ? globalVars.serverless.apiUrl : "http://localhost:4000"
                const profileResponse = await axios.get(apiUrl + '/profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                const { email } = profileResponse.data;

                if (profileResponse.data.paymentStatus === 'paid') {
                    navigate('/profile');
                    return;
                }

                console.log(isServerless, apiUrl, plan, email, localStorage.getItem('token'));

                const subscriptionResponse = await axios.post(apiUrl + '/create-subscription', {
                    customerEmail: email,
                    priceId: plan, // Replace with your actual price ID
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                } 
                ); //{ withCredentials: true }

                setClientSecret(subscriptionResponse.data.clientSecret);
                setIsLoading(false);
            } catch (err) {
                console.error('Error creating subscription:', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        createSubscription();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const options = {
        clientSecret,
        appearance: { theme: 'stripe' },
    };

    return (
        <Fragment>
            <Navigation />
            <div className="container">
                <div className="section-title text-center">
                    <h2>Registrierung vervollständigen</h2>
                </div>
                {clientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentForm clientSecret={clientSecret} />
                    </Elements>
                )}
            </div>
        </Fragment>
    );
};

export default PaymentPage;