import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, FormControl, InputLabel, MenuItem, Select, Typography, Alert, Container, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../lp/navigation';
import { globalVars } from '../Constants';
import 'react-international-phone/style.css';
import { defaultCountries, usePhoneInput } from 'react-international-phone';
import { debounce } from 'lodash';

const isServerless = process.env.REACT_APP_SERVERLESS === 'true';

const CheckoutForm = ({ handleChange, formData, handleSubmit, loading, errors, setErrors }) => {
    const { name, email, password, confirmPassword, phoneNumber, emergencyContactPhoneNumber, preferredChannel, preferredChannelEmergencyContact } = formData;
    
    const { inputValue, handlePhoneValueChange, inputRef } = usePhoneInput({
        defaultCountry: 'ch',  // Default country can be set based on your preference
        value: phoneNumber,
        countries: defaultCountries,
        onChange: (data) => handleChange({ target: { name: 'phoneNumber', value: data.phone } }),
    });

    const { inputValue: emergencyPhoneValue, handlePhoneValueChange: handleEmergencyPhoneValueChange, inputRef: emergencyInputRef } = usePhoneInput({
        defaultCountry: 'ch',
        value: emergencyContactPhoneNumber,
        countries: defaultCountries,
        onChange: (data) => handleChange({ target: { name: 'emergencyContactPhoneNumber', value: data.phone } }),
    });

    // Debounced email validation function
    const validateEmail = (email) => {
        handleChange({ target: { name: 'email', value: email } });
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setErrors((prevErrors) => ({ ...prevErrors, email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        }
    }; 

    // Trigger validation on blur or explicitly after typing has stopped
    const handleEmailBlur = () => {
        validateEmail(email);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                {/* Name Field */}
                <Grid item xs={12}>
                    <TextField
                        label="Vorname und Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        required
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                </Grid>

                {/* Email Field */}
                <Grid item xs={12}>
                <TextField
                        label="E-Mail-Adresse"
                        value={email}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleEmailBlur}  // Validate on blur
                        error={!!errors.email}
                        helperText={errors.email}
                        fullWidth
                        variant="outlined"
                        required
                        name="email"
                    />
                </Grid>

                {/* Password Field */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Passwort"
                        name="password"
                        type="password"
                        value={password}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        required
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                </Grid>

                {/* Confirm Password Field */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Passwort bestätigen"
                        name="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        required
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                    />
                </Grid>

                {/* Phone Number Field */}
                <Grid item xs={12}>
                    <TextField
                        label="Telefonnummer"
                        placeholder="Telefonnummer mit Vorwahl"
                        value={inputValue}
                        onChange={handlePhoneValueChange}
                        inputRef={inputRef}
                        name="phoneNumber"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        FormHelperTextProps={{ style: { fontSize: 18 } }}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber || 'Bitte geben Sie eine gültige Telefonnummer mit Ländervorwahl ein.'}
                    />
                </Grid>

                {/* Preferred Channel */}
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="preferredChannel-label" sx={{ fontSize: 24 }}>
                            Bevorzugter Kanal (SMS oder WhatsApp)
                        </InputLabel>
                        <Select
                            labelId="preferredChannel-label"
                            id="preferredChannel"
                            name="preferredChannel"
                            value={preferredChannel}
                            onChange={handleChange}
                            label="Bevorzugter Kanal (SMS oder WhatsApp)"
                            sx={{ fontSize: 24 }}
                        >
                            <MenuItem value="sms" sx={{ fontSize: 24 }}>SMS</MenuItem>
                            <MenuItem value="whatsapp" sx={{ fontSize: 24 }}>WhatsApp</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Emergency Contact Phone Number */}
                <Grid item xs={12}>
                    <TextField
                        label="Telefonnummer von Angehöriger/Angehörigem"
                        placeholder="Telefonnummer mit Vorwahl"
                        value={emergencyPhoneValue}
                        onChange={handleEmergencyPhoneValueChange}
                        inputRef={emergencyInputRef}
                        name="emergencyContactPhoneNumber"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        error={!!errors.emergencyContactPhoneNumber}
                        helperText={errors.emergencyContactPhoneNumber || "Bitte geben Sie eine Telefonnummer mit Ländervorwahl ein, falls Sie nicht auf unsere tägliche Nachricht reagieren."}
                        FormHelperTextProps={{ style: { fontSize: 18 } }}
                    />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        size="large"
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Neu registrieren'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        emergencyContactPhoneNumber: '',
        preferredChannelEmergencyContact: 'sms',
        preferredChannel: 'sms'
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        // Email validation
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            formIsValid = false;
            newErrors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
        }

        // Phone number validation (require country code)
        if (!/^\+\d{1,3}\d{4,14}(?:x.+)?$/i.test(formData.phoneNumber)) {
            formIsValid = false;
            newErrors.phoneNumber = 'Bitte geben Sie eine gültige Telefonnummer mit Ländervorwahl ein.';
        }

        // Emergency contact phone number validation (require country code)
        if (!/^\+\d{1,3}\d{4,14}(?:x.+)?$/i.test(formData.emergencyContactPhoneNumber)) {
            formIsValid = false;
            newErrors.emergencyContactPhoneNumber = 'Bitte geben Sie eine gültige Telefonnummer für den Notfallkontakt mit Ländervorwahl ein.';
        }

        // Password validation
        if (formData.password.length < 6) {
            formIsValid = false;
            newErrors.password = 'Das Passwort muss mindestens 6 Zeichen lang sein.';
        }

        // Confirm password validation
        if (formData.password !== formData.confirmPassword) {
            formIsValid = false;
            newErrors.confirmPassword = 'Die Passwörter stimmen nicht überein.';
        }

        setErrors(newErrors);
        console.log('Errors:', newErrors);
        return formIsValid;
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        console.log('Form data:', formData);
        if (!validateForm()) {
            setLoading(false);
            console.log('form is invalid')
            return;
        }

        try {
            const apiUrl = isServerless ? globalVars.serverless.apiUrl : "http://localhost:4000";
            const subscriptionResponse = await axios.post(apiUrl + '/register', { ...formData });

            localStorage.setItem('token', subscriptionResponse.data.token);
            navigate('/payment', { state: { email: formData.email } });
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.message === 'User already exists') {
                setError('Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.');
            } else {
                console.error('There was an error with the registration:', error);
                setError('Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.' + error.message);
            }
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Navigation />
            <Container maxWidth="md" sx={{ mt: 5 }}>
                <div className="section-title text-center">
                    <Typography variant="h4" gutterBottom>
                        Neu registrieren
                    </Typography>
                </div>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}
                <CheckoutForm 
                    handleChange={handleChange} 
                    formData={formData} 
                    handleSubmit={handleSubmit} 
                    loading={loading}
                    errors={errors}
                    setErrors={setErrors}
                />
            </Container>
        </Fragment>
    );
};

export default Register;



/*
import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, FormControl, InputLabel, MenuItem, Select, InputAdornment, Typography, Alert, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../lp/navigation';
import "../LandingPage.css";
import { globalVars } from '../Constants';
import 'react-international-phone/style.css';
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';

const CheckoutForm = ({ handleChange, formData, handleSubmit, loading }) => {
    const { name, email, password, confirmPassword, phoneNumber, emergencyContactPhoneNumber, preferredChannel, preferredChannelEmergencyContact } = formData;

    return (
        <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
            
            <Grid item xs={12}>
                <TextField
                    label="Vorname und Name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>

            
            <Grid item xs={12}>
                <TextField
                    label="E-Mail-Adresse"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>

            
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Passwort"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>

            
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Passwort bestätigen"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>
            
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="preferredChannel-label" sx={{ fontSize: 24 }}>
                        Bevorzugter Kanal (SMS oder WhatsApp)
                    </InputLabel>
                    <Select
                        labelId="preferredChannel-label"
                        id="preferredChannel"
                        name="preferredChannel"
                        value={preferredChannel}
                        onChange={handleChange}
                        label="Bevorzugter Kanal (SMS oder WhatsApp)"
                        sx={{ fontSize: 24 }}  // Increase label size
                    >
                        <MenuItem value="sms" sx={{ fontSize: 24 }}>SMS</MenuItem>
                        <MenuItem value="whatsapp" sx={{ fontSize: 24 }}>WhatsApp (empfohlen)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    label="Telefonnummer"
                    placeholder="Telefonnummer mit Vorwahl"
                    value={phoneNumber}
                    onChange={handleChange}
                    name="phoneNumber"
                    type="tel"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>


            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Weitere Angaben
                </Typography><FormControl fullWidth variant="outlined" required>
                    <InputLabel id="preferredChannelEmergencyContact-label" sx={{ fontSize: 24 }}>
                        Kontaktmethode für Angehörige/n auswählen
                    </InputLabel>
                    <Select
                        labelId="preferredChannelEmergencyContact-label"
                        id="preferredChannelEmergencyContact"
                        name="preferredChannelEmergencyContact"
                        value={preferredChannelEmergencyContact}
                        onChange={handleChange}
                        label="Kontaktmethode für Angehörige/n auswählen"
                        sx={{ fontSize: 24 }}  // Increase label size
                    >
                        <MenuItem value="sms" sx={{ fontSize: 24 }}>SMS</MenuItem>
                        <MenuItem value="whatsapp" sx={{ fontSize: 24 }}>WhatsApp (empfohlen)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            
            <Grid item xs={12}>
                
                <TextField
                    label="Telefonnummer von Angehöriger/Angehörigem"
                    name="emergencyContactPhoneNumber"
                    value={emergencyContactPhoneNumber}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    required
                    helperText="Bitte geben Sie die Telefonnummer einer Person ein, die benachrichtigt werden soll, falls Sie nicht auf unsere tägliche Nachricht reagieren."
                    FormHelperTextProps={{ style: { fontSize: 18 } }}  // Increase helper text size
                    InputLabelProps={{ style: { fontSize: 24 } }}  // Increase label size
                />
            </Grid>
            

            
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    size="large"
                >
                    Neu registrieren
                </Button>
            </Grid>
        </Grid>
    </form>
    );
};

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '+41',
        isSubscribedToDailyCheckIn: true,
        emergencyContactPhoneNumber: '+41',
        preferredChannelEmergencyContact: 'sms',
        preferredChannel: 'sms'
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const apiUrl = globalVars.isServerless ? globalVars.serverless.apiUrl : "http://localhost:4000";
            const subscriptionResponse = await axios.post(apiUrl + '/register', { ...formData });

            localStorage.setItem('token', subscriptionResponse.data.token);
            navigate('/payment', { state: { email: formData.email } });
        } catch (error) {
            console.error('There was an error with the registration:', error);
            setError('Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.');
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Navigation />
            <Container maxWidth="md" sx={{ mt: 5 }}>
                <div className="section-title text-center">
                    <Typography variant="h4" gutterBottom>
                        Neu registrieren
                    </Typography>
                </div>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}
                <CheckoutForm 
                    handleChange={handleChange} 
                    formData={formData} 
                    handleSubmit={handleSubmit} 
                    loading={loading} 
                />
            </Container>
        </Fragment>
    );
};

export default Register;

*/