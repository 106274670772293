import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Button, Card, CardContent, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Navigation } from './lp/navigation';
import { globalVars } from './Constants';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.removeItem('token'); 
        navigate('/login'); 
    };

    useEffect(() => {
        const message = localStorage.getItem('paymentSuccess');
        if (message) {
            setSuccessMessage(message);
            localStorage.removeItem('paymentSuccess');
        }

        const fetchUserData = async () => {
            try {
                const response = await axios.get(globalVars.serverless.apiUrl + '/profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setUserData(response.data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (isLoading) return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Container>
    );
    
    if (error) return (
        <Container>
            <Typography color="error" variant="h6" align="center">
                {error}
            </Typography>
        </Container>
    );

    return (
        <Fragment>
            <Navigation />
            <Container>
                <div className="section-title text-center">
                    <Typography variant="h4" gutterBottom>
                        Mein Profil
                    </Typography>
                </div>
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 3 }}>
                        {successMessage}
                    </Alert>
                )}
                {userData ? (
                    <Card sx={{ mb: 3 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Name: <strong>{userData.name}</strong>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Email: <strong>{userData.email}</strong>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Telefon: <strong>{userData.phoneNumber}</strong>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Telefonnummer der Kontaktperson: <strong>{userData.emergencyContactPhoneNumber}</strong>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Abo: <strong>{userData.paymentStatus === "paid" ? "Aktiviert" : "Nicht aktiviert - bitte kontaktieren Sie uns"}</strong>
                            </Typography>
                        </CardContent>
                    </Card>
                ) : (
                    <Typography variant="h6" align="center">
                        Profil nicht geladen - vermutlich sind Sie nicht eingeloggt
                    </Typography>
                )}
                <Button variant="contained" color="secondary" onClick={handleSignOut} fullWidth>
                    Abmelden
                </Button>
            </Container>
        </Fragment>
    );
};

export default Profile;
