import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../lp/navigation';
import { globalVars } from '../Constants';
import { Container, TextField, Button, Typography, Alert, Box } from '@mui/material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const isServerless = process.env.REACT_APP_SERVERLESS === 'true';

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = isServerless ? globalVars.serverless.apiUrl : "http://localhost:4000";
            const response = await axios.post(apiUrl + '/login', { email, password });
            localStorage.setItem('token', response.data.token);
            navigate('/profile');
        } catch (error) {
            setError('Login failed: ' + (error.response?.data?.error || 'Unknown error'));
        }
    };

    return (
        <Fragment>
            <Navigation />
            <Container maxWidth="sm" sx={{ mt: 5 }}>
                <div className="section-title text-center">
                    <Typography variant="h4" gutterBottom>
                        Login
                    </Typography>
                </div>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            label="E-Mail"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            fullWidth
                            required
                        />
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            label="Passwort"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            fullWidth
                            required
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Einloggen
                    </Button>
                </form>
            </Container>
        </Fragment>
    );
};

export default Login;
