import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import blogData from './blog.json';
import { Navigation } from './lp/navigation';

const BlogPost = () => {
    const { postId } = useParams();

    const blogPost = blogData.find((post) => post.id === parseInt(postId));

    const highestId = blogData.reduce((maxId, post) => Math.max(maxId, post.id), 0);

    const prev = parseInt(postId) - 1;
    const next = parseInt(postId) + 1;
    const isFirstPost = parseInt(postId) === 1;
    const isLastPost = parseInt(postId) === highestId;
    console.log(prev, next, isFirstPost, isLastPost);

    if (!blogPost) {
        return <div>Blogbeitrag nicht gefunden.</div>;
    }

    return (
        <Fragment>
            <Navigation />
            <div className="container">
            <a href="/blog" className="btn btn-custom btn-xs page-scroll">
                Zurück zum Blog
            </a>
                <div className="section-title ">
                    <h2>{blogPost.title}</h2>
                    <img
                        src={blogPost.image}
                        alt={blogPost.title}
                        height="200px"
                        width="100%"
                        style={{ objectFit: 'contain' }}
                    />
                    <p>
                        <br />
                    </p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: blogPost.content.replace('ß', 'ss'),
                        }}
                    ></div>
                    <hr />
                   
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td style={{ textAlign: 'left', width: '40%' }}>{!isFirstPost && (
                        <p><a href={`/blog/${prev}`} className="page-scroll">
                             &#8592; Vorheriger Blogbeitrag <br /> {blogData.find((post) => post.id === prev).title}<br /><img src={blogData.find((post) => post.id === prev).image} alt={blogData.find((post) => post.id === prev).title} height="100px" width="100%" style={{ objectFit: "cover" }}/>
                        </a></p>
                    )}</td>
                            <td style={{ textAlign: 'right', width: '20%' }}></td>
                            <td style={{ textAlign: 'right', width: '40%' }}>{!isLastPost && (
                        <p><a href={`/blog/${next}`} className="page-scroll">
                            Nächster Blogbeitrag  &#8594; <br/> {blogData.find((post) => post.id === next).title}<br /><img src={blogData.find((post) => post.id === next).image} alt={blogData.find((post) => post.id === next).title} height="100px" width="100%" style={{ objectFit: "cover" }}/>
                        </a></p>
                    )}</td>
                        </tr>
                    </table>

                    </div>
            </div>
        </Fragment>
    );
};

export default BlogPost;






