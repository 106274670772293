import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { globalVars } from '../Constants';

const isServerless = process.env.REACT_APP_SERVERLESS === 'true';

const PaymentForm = ({ clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/profile`, // Redirect after successful payment
                },
                redirect: 'if_required', // Only redirect if necessary
            });

            if (error) {
                setErrorMessage(error.message);
                setLoading(false);
            } else {
                localStorage.setItem('paymentSuccess', 'Ihre Zahlung war erfolgreich!');
                console.log(paymentIntent);
                navigate('/profile');
            }
        } catch (error) {
            setErrorMessage(error.message);
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading || !stripe}
                sx={{ mt: 3 }}
            >
                Jetzt registrieren
            </Button>
        </form>
    );
};

export default PaymentForm;