import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './auth/Register';
import Login from './auth/Login';
import { AppBar } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Profile from './Profile';
import LandingPage from './LandingPage';
import Privacy from './Privacy';
import Blog from './Blog';
import BlogPost from './BlogPost';
import AGB from './AGB';
import { Contact } from './lp/contact';
//import LandingPage from './LandingPage/src/App';
import JsonData from "./data/data.json";
import ReactGA from 'react-ga4';
import RouteChangeTracker from './RouteChangeTracker';
import PaymentPage from './auth/PaymentPage';

function App() {

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

    ReactGA.initialize('G-GWNWCD60X7');
    

    
    return (
        <Router>
            <div >
                <AppBar position="static">
                </AppBar>
                <RouteChangeTracker />
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/payment" element={<PaymentPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/agb" element={<AGB />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:postId" element={<BlogPost />}>
                    </Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;

// {window.location.pathname !== '/register' &&


/*

<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-GWNWCD60X7"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-GWNWCD60X7');
</script>

*/