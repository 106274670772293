import React from 'react';

export const Pricing = (props) => {
    return (





<div id="about">
<div className="container">
  <div className="row">
   
    <div className="col-xs-12 col-md-6">
      <div className="about-text">
        <h2>Preis</h2>
        <div>
                <div >
                    <h3>Standard</h3>
                    <p>CHF 9.90/Monat</p>
                    <ul>
                        <li>Tägliche Nachricht per WhatsApp</li>
                        <li>Wir kontaktieren eine/n Angehörige/n, falls Sie nicht reagieren</li>
                    </ul>
                    <br />
                    <a
                  href="/register"
                  className="btn btn-custom btn-lg page-scroll"
                >Registrieren</a>
                </div>
            </div>
        </div>
      </div> <div className="col-xs-12 col-md-6">
      {" "}
      <img src="img/esgehtmirgut-screenshot.jpg" className="img-responsive" alt="" />{" "}
    </div>
    </div>
  </div>
</div>

    );
};
/*
<div className="container">
<div className="section-title text-center">
<h2>Preis</h2>
<div >
    <div >
        <h3>Standard</h3>
        <p>CHF 9.90/Monat</p>
        <ul>
            <li>Tägliche Nachricht per WhatsApp</li>
            <li>Wir kontaktieren eine/n Angehörige/n, falls Sie nicht reagieren</li>
        </ul>
        <a
      href="/register"
      className="btn btn-custom btn-lg page-scroll"
    >Registrieren</a>
    </div>
</div></div>
</div>

*/