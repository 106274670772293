import React, { Fragment } from 'react';
import { globalVars } from './Constants';
import { Navigation } from './lp/navigation';

const AGB = () => {
    return (
        <Fragment>
            <Navigation />
            <div className='container'>
                <div className="section-title text-center">

            <h2>Allgemeine Geschäftsbedingungen (AGB) für EsGehtMirGut.ch</h2></div>

            <div className='container'>
            <h3>1. Geltungsbereich</h3>

<p>Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") regeln das Vertragsverhältnis zwischen Hanke Consulting (nachfolgend "Anbieter") und den Kunden (nachfolgend "Kunde") des Online-Dienstes EsGehtMirGut.ch, der unter der Webseite www.esgehtmirgut.ch zugänglich ist.</p>

<h3>2. Vertragsgegenstand</h3>

<p>Der Anbieter stellt dem Kunden einen Online-Dienst (nachfolgend "Service") zur Verfügung, der dem Kunden eine tägliche Kurznachricht schickt bzw. bei Nichtbeantwortung dieser Nachricht dann einer bei der Registrierung gemeldeten Kontaktperson eine Nachricht schickt. Der Service ist ausschliesslich für den persönlichen und nicht-kommerziellen Gebrauch bestimmt.</p>

<h3>3. Registrierung und Vertragsabschluss</h3>

<p>3.1. Für die Nutzung des Services ist eine Registrierung erforderlich. Mit der Registrierung erklärt sich der Kunde mit diesen AGB einverstanden und es kommt ein Vertragsverhältnis zustande.</p>

<p>3.2. Der Kunde verpflichtet sich, bei der Registrierung wahre, aktuelle und vollständige Angaben zu machen und diese bei Änderungen umgehend zu aktualisieren.</p>

<h3>4. Leistungen und Verfügbarkeit</h3>

<p>4.1. Der Anbieter bietet den Kunden Zugang zum Dienst. Die genauen Leistungsmerkmale ergeben sich aus der jeweiligen Leistungsbeschreibung auf der Webseite.</p>

<p>4.2. Der Anbieter bemüht sich um eine möglichst ununterbrochene Verfügbarkeit des Services, garantiert diese jedoch nicht. Insbesondere kann es zu Ausfallzeiten kommen, die für Wartungsarbeiten genutzt werden.</p>

<h3>5. Zahlungsbedingungen</h3>

<p>5.1. Der Service wird gegen eine monatliche Gebühr angeboten. Alle Preise verstehen sich inklusive der gesetzlichen Mehrwertsteuer.</p>

<p>5.2. Die Zahlung der Gebühr erfolgt monatlich im Voraus. Der Kunde kann zwischen verschiedenen Zahlungsmethoden wählen, die auf der Webseite angeboten werden.</p>

<h3>6. Kündigung</h3>

<p>6.1. Das Vertragsverhältnis kann von beiden Seiten jederzeit mit einer Frist von 30 zum Ende eines Abrechnungszeitraums gekündigt werden.</p>

<p>6.2. Das Recht zur ausserordentlichen Kündigung aus wichtigem Grund bleibt unberührt.</p>

<h3>7. Haftung</h3>

<p>7.1. Der Anbieter haftet im Rahmen der gesetzlichen Bestimmungen für Vorsatz und grobe Fahrlässigkeit. Für leichte Fahrlässigkeit haftet der Anbieter nur bei Verletzung einer wesentlichen Vertragspflicht.</p>

<h3>8. Änderung der AGB</h3>

<p>Der Anbieter behält sich vor, diese AGB zu ändern. Über Änderungen wird der Kunde per E-Mail informiert. Widerspricht der Kunde den geänderten AGB nicht innerhalb von vier Wochen, gelten diese als akzeptiert.</p>

<h3>9. Schlussbestimmungen</h3>

<p>9.1. Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</p>

<p>9.2. Auf das Vertragsverhältnis findet ausschliesslich das Recht der Schweiz Anwendung. Gerichtsstand ist Zürich.</p>

            </div></div>
        </Fragment>
    );
};

export default AGB;




